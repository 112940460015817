import React from "react";
import tw from "tailwind-styled-components";
import styled from "styled-components";
import { Formik, Field, Form } from "formik";
import Loading from "../Loading";
import {
  InputGroup,
  FieldWrapper,
  Label,
  SubmitBtn,
  CombineInputGroup,
} from "../Styles/InputStyles";
import moment from "moment";
import Select from "react-select";
import Model from "../Model";
import { useSelector } from "react-redux";
const EditHarvestingModel = ({
  editAddUser,
  editEntryLoading,
  setEditUser,
  selectedData,
  data,
}) => {
  const user = useSelector((state) => state.UserReducer.user);
  const InitialValues = {
    farmerName: selectedData?.farmerName,

    date: moment(selectedData?.harvestingDate).format("YYYY-MM-DD"),
    gradea: selectedData?.quantityGradeA,
    gradec: selectedData?.quantityGradeB,
    graded: selectedData?.quantityGradeC,

    gradeb: selectedData?.quantityGradeB,
    harvested: selectedData?.hearvestedQuantity,
    moisture: selectedData?.moisture,
    cropType: selectedData?.cropType,
    cropName: selectedData?.cropName,
    id: selectedData?.id,
    farmerId: selectedData?.farmerId,
    productionId: selectedData?.productionId,

  };
  // console.log(InitialValues)
  const SubmitHandler = (values) => editAddUser(values);


  const farmersList = data?.map((item) => ({
    value: item.id,
    label: item.name,
  }))

  return (
    <Model width={`w-11/12 max-w-3xl`} setOpenModel={setEditUser}>
      <Title>Edit Harvesting Detail</Title>

      {editEntryLoading && <Loading />}

      {!editEntryLoading && (
        <Formik initialValues={InitialValues} onSubmit={SubmitHandler}>
          <Form>
            <Wrapper>
              <CombineInputGroup>
                <InputGroup>
                  <Label htmlFor="farmerName">Farmer Name</Label>
                  <FieldWrapper>
                    <Field
                      type="text"
                      name="farmerName"
                      id="farmerName"
                      autoComplete="off"
                      className="truncate"
                      required
                      disabled
                    />
                  </FieldWrapper>
                </InputGroup>
                <InputGroup>
                  <Label htmlFor="cropType">Crop Type</Label>
                  <FieldWrapper>
                    <Field
                      type="text"
                      name="cropName"
                      id="cropType"
                      autoComplete="off"
                      className="truncate"
                      required
                      disabled
                    />
                  </FieldWrapper>
                </InputGroup>
              </CombineInputGroup>
              <CombineInputGroup>
                <>


                  <InputGroup>
                    <Label htmlFor="moisture">Moisture</Label>
                    <FieldWrapper className="relative">
                      <Field
                        type="number"
                        name="moisture"
                        id="moisture"
                        autoComplete="off"
                        className="truncate pr-2"
                        required

                      />
                      <PercentageSign>%</PercentageSign>
                    </FieldWrapper>

                  </InputGroup>
                  <InputGroup>
                    <Label htmlFor="date">Harvested Date</Label>
                    <FieldWrapper>
                      <Field
                        type="date"
                        name="date"
                        id="date"
                        autoComplete="off"
                        className="truncate"
                        required
                      />
                    </FieldWrapper>
                  </InputGroup>

                </>
              </CombineInputGroup>


              <CombineInputGroup>
                <InputGroup>
                  <Label htmlFor="gradea">Quantity Grade A (kg)</Label>
                  <FieldWrapper>
                    <Field
                      type="number"
                      name="gradea"
                      id="gradea"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>
                <InputGroup>
                  <Label htmlFor="gradeb">Quantity Grade B (kg)</Label>
                  <FieldWrapper>
                    <Field
                      type="number"
                      name="gradeb"
                      id="gradeb"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>
                {user.companyName == "Kimolo Super Rice Ltd" && (
                  <>
                    <InputGroup>
                      <Label htmlFor="gradec">Quantity Grade C (kg)</Label>
                      <FieldWrapper>
                        <Field
                          type="number"
                          name="gradec"
                          id="gradec"
                          autoComplete="off"
                          className="truncate"
                          required
                        />
                      </FieldWrapper>
                    </InputGroup>
                    <InputGroup>
                      <Label htmlFor="graded">Quantity Grade D (kg)</Label>
                      <FieldWrapper>
                        <Field
                          type="number"
                          name="graded"
                          id="graded"
                          autoComplete="off"
                          className="truncate"
                          required
                        />
                      </FieldWrapper>
                    </InputGroup>

                  </>

                )}

              </CombineInputGroup>




            </Wrapper>
            <BtnWrapper>
              <SubmitBtn type="submit">Update</SubmitBtn>
            </BtnWrapper>
          </Form>
        </Formik>
      )}
    </Model>
  );
};

const Wrapper = tw.div`
grid gap-6 my-6 px-1 `;

const Title = tw.h2` text-lg md:text-xl lg:text-2xl text-gray-600 font-medium mb-6 text-center`;
const BtnWrapper = tw.div` w-full flex items-center justify-center space-x-10 mt-8`;
const PercentageSign = styled.span`
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translateY(-50%);
`;
export default EditHarvestingModel;
