import React, { useState } from "react";
import tw from "tailwind-styled-components";
import { Formik, Field, Form } from "formik";
import {
  InputGroup,
  FieldWrapper,
  Label,
  SubmitBtn,
  CombineInputGroup,
} from "../Styles/InputStyles";
import Loading from "../Loading";

import Select from "react-select";
import Model from "../Model";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
const AddStockModel = ({
  AddSubAgentLoading,
  postFarmerUser,

  setOpenUploadModel,

  data,
  // companyArr,
}) => {
  const user = useSelector((state) => state.UserReducer.user);
  const { t, i18n } = useTranslation();
  const InitialValues = {

  };
  // console.log(data)
  const SubmitHandler = (values) => {

    postFarmerUser(values);
  };
  const cropList = data?.map((item) => ({
    value: item.cropType,
    label: item.cropName,
  }))
  const genderOption = [
    { value: "1", label: "Male" },
    { value: "2", label: "Female" },
  ];

  return (
    <Model width={`w-11/12 max-w-3xl `} setOpenModel={setOpenUploadModel}>
      <Title>Add Stock Management </Title>

      {AddSubAgentLoading && <Loading />}

      {!AddSubAgentLoading && (
        <Formik initialValues={InitialValues} onSubmit={SubmitHandler}>
          <Form>
            <Wrapper>

              <InputGroup>
                <Label htmlFor="cropType">CropType</Label>
                <FieldWrapper $select={true}>
                  <Field required name="cropType" id="cropType" autoComplete="off"  >
                    {(props) => (
                      <Select
                        className="w-full h-full"

                        required
                        options={cropList}
                        classNamePrefix="select"

                        onChange={(val) => {
                          props.form.setFieldValue("cropType", val.value)
                        }
                        }

                      />
                    )}
                  </Field>
                </FieldWrapper>
              </InputGroup>

              <CombineInputGroup>
                <InputGroup>
                  <Label htmlFor="gradea">Quantity Received Grade A(kg)</Label>
                  <FieldWrapper>
                    <Field
                      type="number"
                      name="gradea"
                      id="gradea"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>

                <InputGroup>
                  <Label htmlFor="gradeb">Quantity Received Grade B(kg)</Label>
                  <FieldWrapper>
                    <Field
                      type="number"
                      name="gradeb"
                      id="gradeb"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>
                {user.companyName == "Kimolo Super Rice Ltd" && (
                  <>
                    <InputGroup>
                      <Label htmlFor="gradec">Quantity Received Grade C(kg)</Label>
                      <FieldWrapper>
                        <Field
                          type="number"
                          name="gradec"
                          id="gradec"
                          autoComplete="off"
                          className="truncate"
                          required
                        />
                      </FieldWrapper>
                    </InputGroup>

                    <InputGroup>
                      <Label htmlFor="graded">Quantity Received Grade D(kg)</Label>
                      <FieldWrapper>
                        <Field
                          type="number"
                          name="graded"
                          id="graded"
                          autoComplete="off"
                          className="truncate"
                          required
                        />
                      </FieldWrapper>
                    </InputGroup>


                  </>)}
              </CombineInputGroup>

              <CombineInputGroup>
                <InputGroup>
                  <Label htmlFor="recvdate">Receiving Date</Label>
                  <FieldWrapper>
                    <Field
                      type="date"
                      name="recvdate"
                      id="recvdate"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>
                <InputGroup>
                  <Label htmlFor="source">Source</Label>
                  <FieldWrapper>
                    <Field name="source" id="source" type="text" autoComplete="off" required />
                  </FieldWrapper>
                </InputGroup>
              </CombineInputGroup>


              <CombineInputGroup>
                <InputGroup>
                  <Label htmlFor="quantityissuedGradeA">Quantity Issued Grade A(kg)</Label>
                  <FieldWrapper>
                    <Field
                      type="number"
                      name="quantityissuedGradeA"
                      id="quantityissuedGradeA"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>

                <InputGroup>
                  <Label htmlFor="quantityissuedGradeB">Quantity Issued Grade B(kg)</Label>
                  <FieldWrapper>
                    <Field
                      type="number"
                      name="quantityissuedGradeB"
                      id="quantityissuedGradeB"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>
                {user.companyName == "Kimolo Super Rice Ltd" && (
                  <>
                    <InputGroup>
                      <Label htmlFor="quantityissuedGradec">Quantity Issued Grade C(kg)</Label>
                      <FieldWrapper>
                        <Field
                          type="number"
                          name="quantityissuedGradec"
                          id="quantityissuedGradec"
                          autoComplete="off"
                          className="truncate"
                          required
                        />
                      </FieldWrapper>
                    </InputGroup>

                    <InputGroup>
                      <Label htmlFor="quantityissuedGraded">Quantity Issued Grade D(kg)</Label>
                      <FieldWrapper>
                        <Field
                          type="number"
                          name="quantityissuedGraded"
                          id="quantityissuedGraded"
                          autoComplete="off"
                          className="truncate"
                          required
                        />
                      </FieldWrapper>
                    </InputGroup>




                  </>)}
              </CombineInputGroup>


              <CombineInputGroup>
                <InputGroup>
                  <Label htmlFor="balance">Balance Quantity Grade A (kg)</Label>
                  <FieldWrapper>
                    <Field
                      type="number"
                      name="balance"
                      id="balance"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>

                <InputGroup>
                  <Label htmlFor="balanceb">Balance Quantity Grade B (kg)</Label>
                  <FieldWrapper>
                    <Field
                      type="number"
                      name="balanceb"
                      id="balanceb"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>
                {user.companyName == "Kimolo Super Rice Ltd" && (
                  <>
                    <InputGroup>
                      <Label htmlFor="balancec">Balance Quantity Grade C(kg)</Label>
                      <FieldWrapper>
                        <Field
                          type="number"
                          name="balancec"
                          id="balancec"
                          autoComplete="off"
                          className="truncate"
                          required
                        />
                      </FieldWrapper>
                    </InputGroup>

                    <InputGroup>
                      <Label htmlFor="balanced">Balance Quantity Grade D(kg)</Label>
                      <FieldWrapper>
                        <Field
                          type="number"
                          name="balanced"
                          id="balanced"
                          autoComplete="off"
                          className="truncate"
                          required
                        />
                      </FieldWrapper>
                    </InputGroup>



                  </>)}

              </CombineInputGroup>

              <CombineInputGroup>
                <InputGroup>
                  <Label htmlFor="destination" >Destination</Label>
                  <FieldWrapper>
                    <Field name="destination" id="destination" type="text" autoComplete="off" required />
                  </FieldWrapper>
                </InputGroup>
                <InputGroup>
                  <Label htmlFor="issueddate">Issued Date</Label>
                  <FieldWrapper>
                    <Field
                      type="date"
                      name="issueddate"
                      id="issueddate"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>
              </CombineInputGroup>


            </Wrapper>
            <BtnWrapper>
              <SubmitBtn type="submit">Add</SubmitBtn>
            </BtnWrapper>
          </Form>
        </Formik>
      )}
    </Model>
  );
};

const Wrapper = tw.div`
grid  gap-6 my-6 px-1`;


const Title = tw.h1`text-lg md:text-xl font-medium text-center text-gray-800`;
const BtnWrapper = tw.div`flex justify-center items-center mt-8`;

export default AddStockModel;
