import React, { useEffect, useState } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';


ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);
export function TransportLine({ data }) {
    const [filteredData, setFilteredData] = useState({});

    useEffect(() => {
        if (data) {
            setFilteredData(data?.data?.data);
        }
    }, [data?.data?.data]);
    // console.log(data?.data?.data)
    const labels = filteredData?.totalCropsTranspotation
        ? filteredData.totalCropsTranspotation.map((item) => item.name)
        : [];
    // console.log(labels)
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            // title: {
            //     display: true,
            //     text: 'Chart.js Line Chart',
            // },
        },
    };



    const harvestingData = {
        labels,
        datasets: [
            {
                label: 'Quantity of bags of crop were transported',
                data: filteredData?.totalCropsTranspotation
                    ? filteredData.totalCropsTranspotation.map((item) => item.cropQuantity)
                    : [],
                borderColor: 'rgba(153, 102, 255, 1)',
                backgroundColor: 'rgba(153, 102, 255, 1)',
            },

        ],
    };


    return <Line options={options} data={harvestingData} />;
}
