import React, { useEffect, useState } from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import { PrevBtn, NextBtn, PaginationWrapper } from "../Styles/PageStyles";
import Images from "../../Images";
import tw from "tailwind-styled-components";
import { Link } from "react-router-dom";
import moment from "moment";
import { PaginationLimit } from "../../Config";
import { HiEye } from "react-icons/hi";
import Config from "../../Config";
import { FaFileDownload, FaPlus, FaFileExport } from "react-icons/fa";
import { CiViewList } from "react-icons/ci";
import {
    MdOutlineDescription,
    MdDelete,
    MdModeEdit,
    MdPassword,
    MdSecurityUpdateWarning,
    MdViewCompactAlt,
} from "react-icons/md";
import {
    CollectedBadge,
    PendingBadge,
    ReadyBadge,
    SuccessBadge,
    InActiveBadge,
} from "../Styles/InputStyles";

import { useSelector } from "react-redux";

const TableContainers = ({
    ApiData,
    setSelectedData,
    setEditUser,
    setViewDetail,
    setAddSample,
    setEditSample,
    setUpdateSample,
    setDescription,
    setGeneratCertificate,
    setEditStatus,
}) => {
    const user = useSelector((state) => state.UserReducer.user);
    const [data, setData] = useState([
        {
            deviceName: "",
            extractionTime: "",
            processingTime: "",
            drops: "",
            action: "",
        },
    ]);


    const edit = (item) => {
        setEditUser(true);
        setSelectedData(item);
    };
    const updateStatus = (item) => {
        setEditStatus(true);
        setSelectedData(item);
    };
    const editSample = (item) => {
        setEditSample(true);
        setSelectedData(item);
    };
    const updateSample = (item) => {
        setUpdateSample(true);
        setSelectedData(item);
    };
    const add = (item) => {
        setAddSample(true);
        setSelectedData(item);
    };
    const desc = (item) => {
        setDescription(true);
        setSelectedData(item);
    };
    const generate = (item) => {
        setGeneratCertificate(true);
        setSelectedData(item);
    };

    const viewDetail = (item) => {
        setViewDetail(true);
        setSelectedData(item);
    };

    const getStatus = (status) => {
        if (status == Config.containerStatus.PENDING) {
            return <PendingBadge>Pending</PendingBadge>;
        } else if (status == Config.containerStatus.DISPATCHED) {
            return <ReadyBadge>Dispatched</ReadyBadge>;
        } else if (status == Config.containerStatus.REJECTED) {
            return <InActiveBadge>Rejected</InActiveBadge>;
        } else if (status == Config.containerStatus.APPROVED) {
            return <SuccessBadge>Approved</SuccessBadge>;
        }
    };


    const getGenerate = (certificatePath, id, blNumber) => {

        if (certificatePath) {

            return (

                <a href={certificatePath} target="_blank" className="text-blue-500">
                    Click to View
                </a>

            );
        }
        // else if (user.userType === Config.userType.DOCUMENTCOLLECTOR) {
        //   return (
        //     <Link
        //       className="text-blue-500"
        //       to={{
        //         pathname: `/generate-certificate/${id}/${blNumber}`,
        //       }}
        //     >
        //       Click to Generate
        //     </Link>
        //   );
        // }
        // else {
        //   return (
        //     <p className="text-black">Not Generated</p>)
        // }
        else {
            if (
                user.userType === Config.userType.DOCUMENTCOLLECTOR
            ) {
                return (
                    <Link
                        className="text-blue-500"
                        to={{
                            pathname: `/generate-certificate/${id}/${blNumber}`,
                        }}
                    >
                        Click to Generate
                    </Link>
                );
            }
            else {
                return (
                    <p >Not Generated</p>)
            }
        }

    };




    const ActionBtns = (item) => {
        return (
            <ActionBtnWrapper>
                <Btn title="View All Details" onClick={() => viewDetail(item)}>
                    <HiEye className="text-gray-700" />
                </Btn>
                {(user.userType == Config.userType.DOCUMENTCOLLECTOR) &&
                    <><Btn title="Upload Document" onClick={() => edit(item)}>
                        <MdModeEdit className="text-gray-700" />
                    </Btn>
                    </>}
                {/* <a href={item.certificatePath} download target="_blank">
              <Btn title="Download Certificate">
                <FaFileDownload className="text-gray-700" />
              </Btn>
            </a> */}
                {/* <Link to={`/generate-certificate/${item.id}`}>
              <Btn title="Release Certificate">
                <FaFileExport className="text-gray-700" />
              </Btn>
            </Link> */}

                {(user.userType == Config.userType.DOCUMENTCOLLECTOR) &&
                    item.certificatePath && (
                        <Link to={`/generate-certificate/${item.id}/${item.blNumber}`}>
                            <Btn title="Re-generate Release Certificate">
                                <FaFileExport className="text-gray-700" />
                            </Btn>
                        </Link>
                    )}
                {/* {(user.userType == Config.userType.DOCUMENTCOLLECTOR) && 
         <Btn title="Generate Certificate" onClick={() => generate(item)}>
            <FaFileExport className="text-gray-700" />
          </Btn> 
        } */}
                {(user.userType == Config.userType.SAMPLECOLLECTOR) &&
                    item.certificatePath && item.status != Config.clientStatus.READY && item.isSampleAvailable != false && (
                        <Link to={`/add-sample/${item.id}/${item.blNumber}`}>
                            <Btn title="Add Sample">
                                <FaPlus className="text-gray-700" />
                            </Btn>
                        </Link>
                    )}
                {/* {(user.userType == Config.userType.SAMPLECOLLECTOR || user.userType == Config.userType.ADMIN) &&
          item.status == Config.clientStatus.REJECTED && (
            <Link to={`/edit-sample/${item.id}/${item.blNumber}`}>
              <Btn title="Edit Sample">
                <FaPlus className="text-gray-700" />
              </Btn>
            </Link>
          )} */}
                {(user.userType == Config.userType.SAMPLECOLLECTOR) &&
                    item.status == Config.clientStatus.COLLECTED && item.certificatePath && (
                        <Link to={`/view-sample-details/${item.id}/${item.blNumber}`}>
                            <Btn title="View Sample Details">
                                <MdViewCompactAlt className="text-gray-700" />
                            </Btn>
                        </Link>
                    )}

                {(user.userType == Config.userType.QUALITYASSURER || user.userType == Config.userType.ADMIN) &&
                    (item.status == Config.clientStatus.COLLECTED || item.status == Config.clientStatus.READY) && item.certificatePath && (
                        <Link to={`/update-sample-details/${item.id}/${item.blNumber}`}>
                            {/* <Btn title="Update Sample Details"> */}
                            <Btn title={user.userType === Config.userType.QUALITYASSURER ? "Update Sample Details" : "View Sample Details"}>
                                <MdSecurityUpdateWarning className="text-gray-700" />
                            </Btn>
                        </Link>
                    )}
                {/* {
          (user.userType == Config.userType.SAMPLECOLLECTOR && item.status == Config.clientStatus.REJECTED) &&
          <Btn title="Edit Sample" onClick={() => editSample(item)}>
            <MdModeEdit className="text-gray-700" />
          </Btn>
        } */}
                {/* {user.userType == Config.userType.QUALITYASSURER &&
          item.status == Config.clientStatus.COLLECTED && (
            <>
              <Btn
                title="Update Sample Result"
                onClick={() => updateSample(item)}
              >
                <MdSecurityUpdateWarning className="text-gray-700" />
              </Btn>
              <Btn title="Description" onClick={() => desc(item)}>
                <MdOutlineDescription className="text-gray-700" />
              </Btn>
            </>
          )} */}

            </ActionBtnWrapper>
        );
    };

    useEffect(() => {
        if (ApiData) {
            const tempData = ApiData.map((item) => ({
                clientId: item.id || "N/A",
                containerNo: item.containerNo || "N/A",
                dischargingPort: item.dischargingPort || "N/A",
                mobile: item.mobile !== undefined && item.mobile !== null && item.mobile != 0 ? item.mobile : "N/A",

                // mobile: item.mobile || "N/A",
                // mobile: item.mobile ? mobile : "N/A",
                blNumber: item.blNumber || "N/A",
                portOfLoading: item.portOfLoading || "N/A",

                size: item.size || "N/A",

                transhipmentPort: item.transhipmentPort || "N/A",
                type: item.type || "N/A",
                status: getStatus(item.status),
                vesselName: item.vesselName || "N/A",
                voyageNo: item.voyageNo || "N/A",
                creationDate: moment(item.created_at).format("DD-MM-YYYY"),

                action: ActionBtns(item),
            }));

            setData(tempData);
        }
    }, [ApiData]);

    const columns = React.useMemo(
        () => [
            // {
            //   Header: "Client Id",
            //   accessor: "clientId",
            // },
            {
                Header: "Container Number",
                accessor: "containerNo",
            },
            {
                Header: "B/L Number",
                accessor: "blNumber",
            },

            {
                Header: "Size/Type",
                accessor: "size",
            },

            // {
            //     Header: "Voyage No.",
            //     accessor: "voyageNo",
            // },
            // {
            //     Header: "Port of Loading",
            //     accessor: "portOfLoading",
            // },
            // {
            //     Header: "Discharging Port",
            //     accessor: "dischargingPort",
            // },
            // {
            //     Header: "Transhipment Port",
            //     accessor: "transhipmentPort",
            // },


            {
                // Header: Config.clientStatus.PENDING === ApiData[0]?.status ? "Status" : "",
                Header: "Status",
                accessor: "status",
            },

            {
                Header: "Creation Date",
                accessor: "creationDate",
            },



            // {
            //     Header: "Action",
            //     accessor: "action",
            // },
        ],
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        pageOptions,
        page,
        state: { pageIndex, pageSize },
        previousPage,
        nextPage,
        setPageSize,
        canPreviousPage,
        canNextPage,
    } = useTable(
        {
            data,
            columns,
        },
        useSortBy,
        usePagination
    );

    useEffect(() => {
        setPageSize(PaginationLimit);
    }, []);

    return (
        <>
            <CustomTable {...getTableProps()}>
                <Thead>
                    {headerGroups.map((headerGroup) => (
                        <Tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    {column.render("Header")}
                                    <span>
                                        {column.isSorted ? (
                                            <img
                                                src={Images.Arrow}
                                                alt="down arrow"
                                                className={`${column.isSortedDesc ? "-rotate-90" : "rotate-90"
                                                    } w-1.5 inline-block ml-1.5`}
                                            />
                                        ) : (
                                            ""
                                        )}
                                    </span>
                                </Th>
                            ))}
                        </Tr>
                    ))}
                </Thead>
                <Tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                        prepareRow(row);
                        return (
                            <Tr {...row.getRowProps()}>
                                {row.cells.map((cell) => {
                                    return (
                                        <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
                                    );
                                })}
                            </Tr>
                        );
                    })}
                </Tbody>
            </CustomTable>

            <PaginationWrapper>
                <div className="px-2">
                    Page{" "}
                    <em>
                        {pageIndex + 1} of {pageOptions.length}
                    </em>
                </div>

                <div className="flex gap-1">
                    <PrevBtn onClick={() => previousPage()} disabled={!canPreviousPage}>
                        <img src={Images.Arrow} alt="arrow" className="w-2 opacity-75 " />
                    </PrevBtn>
                    <NextBtn onClick={() => nextPage()} disabled={!canNextPage}>
                        <img
                            src={Images.Arrow}
                            alt="arrow"
                            className="w-2 rotate-180 opacity-75"
                        />
                    </NextBtn>
                </div>
            </PaginationWrapper>
        </>
    );
};

const ActionBtnWrapper = tw.div`flex items-center space-x-3`;

const CustomTable = tw.table` w-full`;
const Thead = tw.thead`border-b`;
const Tbody = tw.tbody`border-b`;
const Tr = tw.tr`border-b rounded-md overflow-hidden `;
const Th = tw.th`text-left text-sm p-3 font-medium text-gray-500  `;
const Td = tw.td`p-3 text-sm`;

const Btn = tw.button`grid place-items-center border border-gray-300 w-8 h-8 rounded-full transform transition duration-200 hover:scale-110 hover:shadow-md`;

export default TableContainers;
