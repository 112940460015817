import React, { useState } from "react";
import tw from "tailwind-styled-components";
import { Formik, Field, Form } from "formik";
import {
  InputGroup,
  FieldWrapper,
  Label,
  SubmitBtn,
  CombineInputGroup,
} from "../Styles/InputStyles";
import Loading from "../Loading";

import Select from "react-select";
import Model from "../Model";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";
const AddRawMaterialModel = ({
  AddSubAgentLoading,
  postFarmerUser,

  setOpenUploadModel,

  data,
  // companyArr,
}) => {
  const user = useSelector((state) => state.UserReducer.user);

  const [error, setError] = useState('');
  const { t, i18n } = useTranslation();
  const InitialValues = {

  };
  // console.log(data)
  const SubmitHandler = (values) => {
    postFarmerUser(values);
  };
  // 
  const cropNames =
    user?.cropName?.map((crop) => ({
      value: crop.id,
      label: crop.cropName,
    })) || [];

  const calculateBalance = (grade, receivedValue, issuedValue) => {
    // Calculate balance quantity for the given grade
    const netBalance = receivedValue - issuedValue;
    return isNaN(netBalance) ? 0 : netBalance;
  };




  return (
    <Model width={`w-11/12 max-w-3xl `} setOpenModel={setOpenUploadModel}>
      <Title>Add Rawmaterial </Title>

      {AddSubAgentLoading && <Loading />}

      {!AddSubAgentLoading && (
        <Formik initialValues={InitialValues} onSubmit={SubmitHandler}>
          {({ values, setFieldValue }) => (
            <Form>
              <Wrapper>

                <InputGroup>
                  <Label htmlFor="crop">Crop Type</Label>
                  <FieldWrapper $select={true}>
                    <Field
                      required
                      name="crop"
                      id="crop"
                      autoComplete="off"
                    >
                      {(props) => (
                        <Select
                          className="w-full h-full"
                          required
                          options={cropNames}
                          classNamePrefix="select"
                          onChange={(val) => {
                            props.form.setFieldValue("crop", val.value);
                          }}
                        />
                      )}
                    </Field>
                  </FieldWrapper>
                </InputGroup>
                <CombineInputGroup>
                  <InputGroup>
                    <Label htmlFor="gradea">Quantity Received Grade A(kg)</Label>
                    <FieldWrapper>
                      <Field
                        type="number"
                        name="gradea"
                        id="gradea"
                        autoComplete="off"
                        className="truncate"
                        required
                        onChange={(e) => {
                          setFieldValue('gradea', e.target.value);
                          const issuedValue = values['issuegradea'] || 0;
                          const netBalance = calculateBalance('a', e.target.value, issuedValue);
                          setFieldValue('balance', netBalance);

                        }}
                      />
                    </FieldWrapper>
                  </InputGroup>

                  <InputGroup>
                    <Label htmlFor="gradeb">Quantity Received Grade B(kg)</Label>
                    <FieldWrapper>
                      <Field
                        type="number"
                        name="gradeb"
                        id="gradeb"
                        autoComplete="off"
                        className="truncate"
                        required
                        onChange={(e) => {
                          setFieldValue('gradeb', e.target.value);
                          const issuedValue = values['issuegradeb'] || 0;
                          const netBalance = calculateBalance('b', e.target.value, issuedValue);
                          setFieldValue('balanceb', netBalance);
                        }}
                      />
                    </FieldWrapper>
                  </InputGroup>
                  {user.companyName == "Kimolo Super Rice Ltd" && (
                    <>
                      <InputGroup>
                        <Label htmlFor="gradec">Quantity Received Grade C(kg)</Label>
                        <FieldWrapper>
                          <Field
                            type="number"
                            name="gradec"
                            id="gradec"
                            autoComplete="off"
                            className="truncate"
                            required
                            onChange={(e) => {
                              setFieldValue('gradec', e.target.value);
                              const issuedValue = values['issuegradec'] || 0;
                              const netBalance = calculateBalance('c', e.target.value, issuedValue);
                              setFieldValue('balancec', netBalance);
                            }}
                          />
                        </FieldWrapper>
                      </InputGroup>

                      <InputGroup>
                        <Label htmlFor="graded">Quantity Received Grade D(kg)</Label>
                        <FieldWrapper>
                          <Field
                            type="number"
                            name="graded"
                            id="graded"
                            autoComplete="off"
                            className="truncate"
                            required
                            onChange={(e) => {
                              setFieldValue('graded', e.target.value);
                              const issuedValue = values['issuegraded'] || 0;
                              const netBalance = calculateBalance('d', e.target.value, issuedValue);
                              setFieldValue('balanced', netBalance);
                            }}
                          />
                        </FieldWrapper>
                      </InputGroup>


                    </>)}
                </CombineInputGroup>
                <CombineInputGroup>
                  <InputGroup>
                    <Label htmlFor="source">Source</Label>
                    <FieldWrapper>
                      <Field
                        type="text"
                        name="source"
                        id="source"
                        autoComplete="off"
                        className="truncate"
                        required
                      />
                    </FieldWrapper>
                  </InputGroup>





                  <InputGroup>
                    <Label htmlFor="date">Receiving Date</Label>
                    <FieldWrapper>
                      <Field
                        type="date"
                        name="date"
                        id="date"
                        autoComplete="off"
                        className="truncate"
                        required
                      />
                    </FieldWrapper>
                  </InputGroup>
                </CombineInputGroup>
                <CombineInputGroup>
                  <InputGroup>
                    <Label htmlFor="issuegradea">Quantity Issued Grade A(kg)</Label>
                    <FieldWrapper>
                      <Field
                        type="number"
                        name="issuegradea"
                        id="issuegradea"
                        autoComplete="off"
                        className="truncate"
                        required
                        onChange={(e) => {
                          setFieldValue('issuegradea', e.target.value);
                          const receivedValue = values['gradea'] || 0;
                          const netBalance = calculateBalance('a', receivedValue, e.target.value);
                          setFieldValue('balance', netBalance);
                        }}
                      />
                    </FieldWrapper>
                  </InputGroup>

                  <InputGroup>
                    <Label htmlFor="issuegradeb">Quantity Issued Grade B(kg)</Label>
                    <FieldWrapper>
                      <Field
                        type="number"
                        name="issuegradeb"
                        id="issuegradeb"
                        autoComplete="off"
                        className="truncate"
                        required
                        onChange={(e) => {
                          setFieldValue('issuegradeb', e.target.value);
                          const receivedValue = values['gradeb'] || 0;
                          const netBalance = calculateBalance('b', receivedValue, e.target.value);
                          setFieldValue('balanceb', netBalance);
                        }}
                      />
                    </FieldWrapper>
                  </InputGroup>
                  {user.companyName == "Kimolo Super Rice Ltd" && (
                    <>
                      <InputGroup>
                        <Label htmlFor="issuegradec">Quantity Issued Grade C(kg)</Label>
                        <FieldWrapper>
                          <Field
                            type="number"
                            name="issuegradec"
                            id="issuegradec"
                            autoComplete="off"
                            className="truncate"
                            required
                            onChange={(e) => {
                              setFieldValue('issuegradec', e.target.value);
                              const receivedValue = values['gradec'] || 0;
                              const netBalance = calculateBalance('c', receivedValue, e.target.value);
                              setFieldValue('balancec', netBalance);
                            }}
                          />
                        </FieldWrapper>
                      </InputGroup>

                      <InputGroup>
                        <Label htmlFor="issuegraded">Quantity Issued Grade D(kg)</Label>
                        <FieldWrapper>
                          <Field
                            type="number"
                            name="issuegraded"
                            id="issuegraded"
                            autoComplete="off"
                            className="truncate"
                            required
                            onChange={(e) => {
                              setFieldValue('issuegraded', e.target.value);
                              const receivedValue = values['graded'] || 0;
                              const netBalance = calculateBalance('d', receivedValue, e.target.value);
                              setFieldValue('balanced', netBalance);
                            }}
                          />
                        </FieldWrapper>
                      </InputGroup>




                    </>)}
                </CombineInputGroup>

                <CombineInputGroup>
                  <InputGroup>
                    <Label htmlFor="balance">Balance Quantity Grade A (kg)</Label>
                    <FieldWrapper>
                      <Field
                        type="number"
                        name="balance"
                        id="balance"
                        autoComplete="off"
                        className="truncate"
                        required
                        readOnly
                      />
                    </FieldWrapper>
                  </InputGroup>

                  <InputGroup>
                    <Label htmlFor="balanceb">Balance Quantity Grade B (kg)</Label>
                    <FieldWrapper>
                      <Field
                        type="number"
                        name="balanceb"
                        id="balanceb"
                        autoComplete="off"
                        className="truncate"
                        required
                        readOnly
                      />
                    </FieldWrapper>
                  </InputGroup>
                  {user.companyName == "Kimolo Super Rice Ltd" && (
                    <>
                      <InputGroup>
                        <Label htmlFor="balancec">Balance Quantity Grade C(kg)</Label>
                        <FieldWrapper>
                          <Field
                            type="number"
                            name="balancec"
                            id="balancec"
                            autoComplete="off"
                            className="truncate"
                            required
                            readOnly
                          />
                        </FieldWrapper>
                      </InputGroup>

                      <InputGroup>
                        <Label htmlFor="balanced">Balance Quantity Grade D(kg)</Label>
                        <FieldWrapper>
                          <Field
                            type="number"
                            name="balanced"
                            id="balanced"
                            autoComplete="off"
                            className="truncate"
                            required
                            readOnly
                          />
                        </FieldWrapper>
                      </InputGroup>



                    </>)}

                </CombineInputGroup>

                <InputGroup>
                  <Label htmlFor="issueDate">Issued Date</Label>
                  <FieldWrapper>
                    <Field
                      type="date"
                      name="issueDate"
                      id="issueDate"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>
              </Wrapper>
              <BtnWrapper>
                <SubmitBtn type="submit">Add</SubmitBtn>
              </BtnWrapper>
            </Form>)}
        </Formik>
      )}
    </Model>
  );
};

const Wrapper = tw.div`
grid  gap-4 my-2 px-1`;


const Title = tw.h1`text-lg md:text-xl font-medium text-center text-gray-800`;
const BtnWrapper = tw.div`flex justify-center items-center mt-8`;

export default AddRawMaterialModel;
