import React from "react";
import tw from "tailwind-styled-components";
import { MdViewHeadline } from "react-icons/md";
import Model from "../Model";
import moment from "moment";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Config from "../../Config";
const ViewDetailModel = ({ setViewDetail, selectedData }) => {
  // console.log(selectedData.SampleStatus)
  const user = useSelector((state) => state.UserReducer.user);
  const getStatus = (selectedData) => {
    if (selectedData.status == Config.clientStatus.PENDING) {
      return <>Pending</>;
    } else if (selectedData.status == Config.clientStatus.COLLECTED) {
      return <>Collected</>;
    } else if (selectedData.status == Config.clientStatus.READY) {
      return <>Ready</>;
    }
    else if (selectedData.status == Config.clientStatus.REJECTED) {
      return <>Rejected</>;
    } else {
      return <>Approved</>;
    }
  };
  const getProcessType = (selectedData) => {
    if (selectedData.processType == Config.processType.Automatic) {
      return <>Automatic</>;
    } else {
      return <>Manually</>;
    }
  };
  const getSampleStatus = (selectedData) => {
    if (selectedData.SampleStatus == Config.sampleStatus.PENDING) {
      return <>Pending</>;
    } else if (selectedData.SampleStatus == Config.clientStatus.APPROVED) {
      return <>Approved</>;
    } else if (selectedData.SampleStatus == Config.clientStatus.REJECTED) {
      return <>Rejected</>;
    }

  };
  return (
    <Model width={`w-11/12 max-w-2xl`} setOpenModel={setViewDetail}>
      <Wrapper>
        <Title><MdViewHeadline className="mt-1" />View Details</Title>
        <SingleItem name={"Company Name"} value={selectedData?.companyName} />
        <SingleItem name={"Client Name"} value={selectedData?.name} />
        <SingleItem name={"Client Mobile"} value={selectedData?.mobile == 0 ? "N/A" : (selectedData?.mobile || "N/A")} />

        {/* <SingleItem name={"Client Mobile"} value={selectedData?.mobile || "N/A"} /> */}
        <SingleItem name={"Client Email"} value={selectedData?.email || "N/A"} />

        <SingleItem name={"Client Address"} value={selectedData?.address} />
        <SingleItem name={"B/L Number"} value={selectedData?.blNumber} />
        <SingleItem name={"Type"} value={getProcessType(selectedData) || "N/A"} />
        {Config.clientStatus.PENDING == selectedData.status && (
          <SingleItem name={"Status"} value={getStatus(selectedData) || "N/A"} />)}
        {selectedData?.certificatePath &&
          <SingleItem
            name={"Release Certificate"}
            value={
              <LinkValue>
                <a href={selectedData?.certificatePath} target="_blank">
                  Click to View
                </a>
              </LinkValue>
            }
          />}

        {!selectedData?.certificatePath && selectedData?.payReciptPath && selectedData?.invoicePath && selectedData?.ladingBillPath &&
          <SingleItem
            name={"Release Certificate"}
            value={
              <LinkValue>

                <Link to={`/generate-certificate/${selectedData?.id}/${selectedData?.blNumber}`} >

                  Click to Generate
                </Link>
              </LinkValue>
            }
          />}
        {selectedData?.ladingBillPath &&
          <SingleItem
            name={"Bill of Lading"}
            value={
              <LinkValue>
                <a href={selectedData?.ladingBillPath} target="_blank">
                  Click to View
                </a>
              </LinkValue> || "N/A"
            }
          />}
        {selectedData?.payReciptPath &&
          <SingleItem
            name={"Payment Receipt"}
            value={
              <LinkValue>
                <a href={selectedData?.payReciptPath} target="_blank">
                  Click to View
                </a>
              </LinkValue> || "N/A"
            }
          />}
        {selectedData?.invoicePath &&
          <SingleItem
            name={"Invoice"}
            value={
              <LinkValue>
                <a href={selectedData?.invoicePath} target="_blank">
                  Click to View
                </a>
              </LinkValue> || "N/A"
            }
          />}
        <SingleItem name={"Package Information"} value={selectedData?.packageInfo || "N/A"} />
        {/* {user.userType == Config.userType.QUALITYASSURER && (
          <SingleItem name={"Sample Status"} value={getSampleStatus(selectedData) || "N/A"} />)} */}

        {/* {(user.userType == Config.userType.SAMPLECOLLECTOR && Config.clientStatus.PENDING != selectedData.status) &&
          <><SingleItem name={"Sample Collector Name"} value={selectedData?.sampleCollector} /><SingleItem name={"Sample Count"} value={selectedData?.sampleCount} /><SingleItem name={"Sample Description"} value={selectedData?.sampleDescription} /></>} */}
        {/* {(Config.sampleStatus.REJECTED == selectedData.SampleStatus || Config.userType.INSPECTOR == user.userType) && (
          <SingleItem name={"Rejection Cause"} value={selectedData.rejectionCause || "N/A"} />)} */}
        <SingleItem name={"Creation Date"} value={moment(selectedData?.creationDate).format("DD-MM-YYYY hh:mm A")} />
      </Wrapper>
    </Model>
  );
};

const SingleItem = ({ name, value }) => (
  <div className="flex items-center py-2 border-b border-gray-100">
    <Name>{name}</Name> <p>:</p>
    {<Value>{value}</Value>}
  </div>
);

const Wrapper = tw.div`px-4`;
const Title = tw.h2`flex flex-row gap-2 text-lg md:text-xl mb-6 text-gray-700 font-medium text-left`;
const Name = tw.p`text-sm text-gray-500  w-40`;
const Value = tw.p`word-break  text-sm text-gray-800 font-medium w-80 px-6 `;
const LinkValue = tw.a`text-sm text-blue-500 max-w-xs truncate cursor-pointer`;
export default ViewDetailModel;
