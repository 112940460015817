import React from "react";
import tw from "tailwind-styled-components";
import { Formik, Field, Form } from "formik";
import Loading from "../Loading";
import {
  InputGroup,
  FieldWrapper,
  Label,
  SubmitBtn,
  CombineInputGridGroup,
} from "../Styles/InputStyles";
import {
  FieldCustomWrapperDoc
} from "../Styles/PageStyles";
import DropZone from "../DropZonePdf";
import Model from "../Model";
import { useSelector } from "react-redux";
import Config from "../../Config";
import { toast } from "react-toastify";
const EditClientModel = ({
  editAddUser,
  editEntryLoading,
  setEditUser,
  selectedData,
  data,
}) => {

  const user = useSelector((state) => state.UserReducer.user);


  const InitialValues = {

    invoice: "",
    paymentReceipt: "",
    ladingBill: "",
    id: selectedData.id,
    blNumber: selectedData.blNumber,

  };
  // console.log(InitialValues)

  // const SubmitHandler = (values) => editAddUser(values);
  const SubmitHandler = (values) => {


    if (!values.ladingBill) {
      toast.error("Please upload bill of lading ");
      return;
    }
    if (!values.paymentReceipt) {
      toast.error("Please upload payment receipt. ");
      return;
    }
    if (!values.invoice) {
      toast.error("Please upload invoice");
      return;
    }

    // if (user.userType === Config.userType.ADMIN) {
    //   const { ladingBill, paymentReceipt, invoice } = values;
    //   if (!ladingBill || !paymentReceipt || !invoice) {
    //     toast.error("Lading Bill, Payment Receipt, and Invoice are required.");
    //     return;
    //   }
    // }
    editAddUser(values);
  };

  return (
    <Model width={`w-11/12 max-w-xl`} setOpenModel={setEditUser}>
      <Title>Upload Client Document</Title>

      {editEntryLoading && <Loading />}

      {!editEntryLoading && (
        <Formik initialValues={InitialValues} onSubmit={SubmitHandler}>
          <Form>
            <Wrapper>



              <InputGroup>
                <Label htmlFor="ladingBill">Bill of Lading(BOL)</Label>
                <FieldCustomWrapperDoc $select={true}>
                  <Field
                    name="ladingBill"
                    type="text"
                    id="ladingBill"
                    autoComplete="off"
                  // required
                  >
                    {(props) => (
                      <DropZone
                        fields={props.field}
                        setFieldValue={props.form.setFieldValue}
                        componentFor="ladingBill"
                        acceptFiles="application/pdf"
                        File={props.field.value ? [props.field.value] : null} />
                    )}
                  </Field>
                </FieldCustomWrapperDoc>
                <p className="text-xs text-gray-500 mt-2 ">
                  Max size: 10MB
                </p>
              </InputGroup>
              <InputGroup>
                <Label htmlFor="paymentReceipt">Payment Receipt</Label>
                <FieldCustomWrapperDoc $select={true}>
                  <Field
                    name="paymentReceipt"
                    type="text"
                    id="paymentReceipt"
                    autoComplete="off"
                  // required
                  >
                    {(props) => (
                      <DropZone
                        fields={props.field}
                        setFieldValue={props.form.setFieldValue}
                        componentFor="paymentReceipt"
                        acceptFiles="application/pdf"
                        File={props.field.value ? [props.field.value] : null} />
                    )}
                  </Field>
                </FieldCustomWrapperDoc>
                <p className="text-xs text-gray-500 mt-2 ">
                  Max size: 10MB
                </p>
              </InputGroup>

              <InputGroup>
                <Label htmlFor="invoice">Invoice</Label>
                <FieldCustomWrapperDoc $select={true}>
                  <Field
                    name="invoice"
                    type="text"
                    id="invoice"
                    autoComplete="off"
                  // required
                  >
                    {(props) => (
                      <DropZone
                        fields={props.field}
                        setFieldValue={props.form.setFieldValue}
                        componentFor="invoice"
                        acceptFiles="application/pdf"
                        File={props.field.value ? [props.field.value] : null} />
                    )}
                  </Field>
                </FieldCustomWrapperDoc>
                <p className="text-xs text-gray-500 mt-2 ">
                  Max size: 10MB
                </p>
              </InputGroup>


            </Wrapper>
            <BtnWrapper>
              <SubmitBtn type="submit">Submit</SubmitBtn>
            </BtnWrapper>
          </Form>
        </Formik>
      )}
    </Model>
  );
};

const Wrapper = tw.div`
grid gap-6 my-6 px-1 `;

const Title = tw.h2` text-lg md:text-xl lg:text-2xl text-gray-600 font-medium mb-6 text-center`;
const BtnWrapper = tw.div` w-full flex items-center justify-center space-x-10 mt-8`;

export default EditClientModel;
