import React, { useState } from "react";
import tw from "tailwind-styled-components";
import { Formik, Field, Form } from "formik";
import Loading from "../Loading";
import { toast } from "react-toastify";
import {
    InputGroup,
    FieldWrapper,
    Label,
    SubmitBtn,
    CombineInputGroup,
} from "../Styles/InputStyles";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Model from "../Model";
import CustomPhoneInput from "../CountryCode";
const UpdateSample = ({
    UpdateSampleLoading,
    updateSampleUser,
    setUpdateSample,
    selectedData,
}) => {
    const [showRejectionCause, setShowRejectionCause] = useState(false);
    const InitialValues = {
        id: selectedData?.id,
        action: "",
        description: selectedData?.sampleDescription,
        rejectionCause: "",
    };

    // const SubmitHandler = (values) => updateSampleUser(values);
    const SubmitHandler = (values) => {
        if (values.action == "3") {
            if (!values.rejectionCause) {
                toast.error("Please write the rejection cause.");
                return;
            }
        }
        // console.log(values);
        updateSampleUser(values);
    };





    return (
        <Model width={`w-11/12 max-w-lg`} setOpenModel={setUpdateSample}>
            <Title>Update Sample Result</Title>
            <Box>If you find all samples from the client to be satisfactory and in compliance with our regulations, please generate the Goods Quality Certificate by clicking the approve button. However, if you come across anything irrelevant or non-compliant with our regulations, kindly reject it and provide a reason for the rejection.</Box>
            {UpdateSampleLoading && <Loading />}

            {!UpdateSampleLoading && (
                <Formik initialValues={InitialValues}
                    onSubmit={SubmitHandler}
                >
                    {({ values, handleChange }) => (
                        <Form>
                            <Wrapper>

                                {/* <InputGroup>
                                    <Label htmlFor="description">
                                        Description
                                    </Label>
                                    <FieldWrapper>
                                        <Field
                                            component="textarea"
                                            row="10"
                                            type="textarea"
                                            name="description"
                                            id="description"
                                            placeholder="
                                        Container (Id:1234E85) : 1 Sample
                                        Container (Id:4586G86) : 1 Sample
                                        "
                                            autoComplete="off"
                                            required
                                            readOnly
                                        />
                                    </FieldWrapper>
                                </InputGroup> */}
                                {showRejectionCause && (
                                    <InputGroup>
                                        <Label htmlFor="rejectionCause">Rejection Cause</Label>
                                        <FieldWrapper>
                                            <Field
                                                component="textarea"
                                                row="10"
                                                type="textarea"
                                                name="rejectionCause"
                                                id="rejectionCause"
                                                placeholder="Reason of rejection"
                                                autoComplete="off"
                                                value={values.rejectionCause}
                                                onChange={handleChange}
                                                required
                                            />
                                        </FieldWrapper>
                                    </InputGroup>
                                )}

                            </Wrapper>
                            <BtnWrapper>
                                {!showRejectionCause && (

                                    <><SubmitBtn
                                        type="button"
                                        onClick={() => {

                                            SubmitHandler({ ...values, action: "2" });
                                        }}
                                    >
                                        Approve
                                    </SubmitBtn><SubmitBtn
                                        type="button"
                                        onClick={() => setShowRejectionCause(true)}

                                    >
                                            Reject
                                        </SubmitBtn></>)}
                                {showRejectionCause && (
                                    <SubmitBtn
                                        type="button"
                                        onClick={() => {

                                            SubmitHandler({ ...values, action: "3" });
                                        }}
                                    >
                                        Submit
                                    </SubmitBtn>)}
                            </BtnWrapper>

                        </Form>
                    )}
                </Formik>
            )}
        </Model>
    );
};



const Wrapper = tw.div`
grid gap-6 my-6 px-1 `;
const Box = tw.div`text-center text-gray-800 border border-gray-100 bg-gray-50 p-5`;

const Title = tw.h2` text-lg md:text-xl lg:text-2xl text-gray-600 font-medium mb-6 text-center`;
const SubTitle = tw.p` text-sm md:text-md lg:text-lg text-gray-600 font-medium mb-6 text-center`;
const BtnWrapper = tw.div` w-full flex items-center justify-center space-x-10 mt-8`;

export default UpdateSample;
