import React, { useEffect, useState } from "react";
import tw from "tailwind-styled-components";
import { Link, useNavigate } from "react-router-dom";
import {

  faHandshake,
  faWallet,
  faHashtag,
  faFileInvoice,

  faMoneyCheck,

} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IoMdApps } from "react-icons/io";
import { MdSubscriptions } from "react-icons/md";
import { useSelector } from "react-redux";
import Config from "../../Config";
import { FaDollarSign } from "react-icons/fa";

// import { Link } from "react-router-dom";
import { FaWheelchair } from "react-icons/fa";
import { FaBriefcaseMedical, FaCalendarPlus, FaFileCircleCheck } from "react-icons/fa";
import { LuView } from "react-icons/lu";
import {

  FaBusinessTime,

  FaUser,
  FaUserPlus,
  FaWarehouse,
  FaBuilding,
  FaTruck,
  FaUserFriends,
} from "react-icons/fa";
import { MdOutlineMyLocation, MdProductionQuantityLimits, MdAssuredWorkload, MdOutlineCollectionsBookmark, MdLibraryAddCheck } from "react-icons/md";
import { IoIosNotifications } from "react-icons/io";
import { MdVerified } from "react-icons/md";
import { RiShieldUserFill } from "react-icons/ri";
import { GiWheat, GiSlicedBread } from "react-icons/gi";
import { SiMarketo } from "react-icons/si";
import { AiOutlineStock } from "react-icons/ai";
import { FcApproval } from "react-icons/fc";
import { GiPoliceOfficerHead } from "react-icons/gi";
import { RxCross2 } from "react-icons/rx";
import { IoIosExit } from "react-icons/io";
import Images from "../../Images";
import { CiSearch } from "react-icons/ci";
import { MdPendingActions } from "react-icons/md";
import { FaUserClock } from "react-icons/fa6";
import { BsFillCollectionFill } from "react-icons/bs";
import { FaUserCheck } from "react-icons/fa";
import { ImExit } from "react-icons/im";
import { LuContainer } from "react-icons/lu";
import { AiOutlineDeliveredProcedure } from "react-icons/ai";
import { FaCheckToSlot } from "react-icons/fa6";
import { RxCrossCircled } from "react-icons/rx";
import { MdPending } from "react-icons/md";

const Summary = (data) => {
  const user = useSelector((state) => state.UserReducer.user);
  const [summaryData, setSummaryData] = useState({
    totalcontainericon: <LuContainer />,
    pendingicon: <MdPending />,
    dispatchicon: <AiOutlineDeliveredProcedure />,
    approveicon: <FaCheckToSlot />,
    rejecticon: <RxCrossCircled />,

    scolIcon: <MdOutlineCollectionsBookmark />,
    qaicon: <MdAssuredWorkload />,
    scicon: <GiPoliceOfficerHead />,
    sapprovedicon: <MdLibraryAddCheck />,
    insicon: <RiShieldUserFill />,
    srejicon: <RxCross2 />,
    dcicon: <FaUser />,
    exiticon: <IoIosExit />,
    clienticon: <FaUserFriends />,
    addUser: <FaUserPlus />,
    searchUser: <CiSearch />,
    pending: <MdPendingActions />,
    pendingClient: <FaUserClock />,
    collectedClient: <BsFillCollectionFill />,
    approvedClient: <FaUserCheck />,
    pendingExit: <ImExit />
  });


  const [filteredData, setFilteredData] = useState({});
  useEffect(() => {
    setFilteredData(data?.data?.data?.data || {});
  });
  // console.log(filteredData)
  return (
    <BookingSummary>
      {user.userType != Config.userType.DOCUMENTCOLLECTOR &&
        <BookingGrid>
          {filteredData?.card &&
            filteredData?.card.map((product, index) => {
              if (
                (user.userType === Config.userType.ADMIN && (product.name === Config.dashboardTypes.Clients || product.name === Config.dashboardTypes.Pending_Client || product.name === Config.dashboardTypes.Collected_Clients || product.name === Config.dashboardTypes.Approved_Clients || product.name === Config.dashboardTypes.Samples_Collected || product.name === Config.dashboardTypes.Samples_Approved || product.name === Config.dashboardTypes.Samples_Rejected || product.name === Config.dashboardTypes.SampleRTE || product.name === Config.dashboardTypes.Pending || product.name === Config.dashboardTypes.Pending_Exit || product.name === Config.dashboardTypes.Total_Container || product.name === Config.dashboardTypes.Pending_Container || product.name === Config.dashboardTypes.Approved_Container || product.name === Config.dashboardTypes.Rejected_Container || product.name === Config.dashboardTypes.Dispatched_Container)) ||

                // (user.userType === Config.userType.DOCUMENTCOLLECTOR && (product.name === Config.dashboardTypes.Clients)) ||
                (user.userType === Config.userType.SAMPLECOLLECTOR && (product.name === Config.dashboardTypes.SampleTC || product.name === Config.dashboardTypes.Samples_Collected || product.name === Config.dashboardTypes.Samples_Rejected || product.name === Config.dashboardTypes.Samples_Approved || product.name === Config.dashboardTypes.Pending)) ||
                (user.userType === Config.userType.QUALITYASSURER && (product.name === Config.dashboardTypes.Samples_Collected || product.name === Config.dashboardTypes.Samples_Approved || product.name === Config.dashboardTypes.Samples_Rejected))
              ) {

                return (
                  <Card
                    key={product.id}
                    name={product.name}
                    desc={product.desc}
                    type={product.name}
                    count={product.count}
                    balance={product.balance}
                    pendingExit={summaryData.pendingExit}
                    pending={summaryData.pending}
                    collectedClient={summaryData.collectedClient}
                    approvedClient={summaryData.approvedClient}
                    pendingClient={summaryData.pendingClient}
                    scolIcon={summaryData.scolIcon}
                    clienticon={summaryData.clienticon}
                    dcicon={summaryData.dcicon}
                    insicon={summaryData.insicon}
                    scicon={summaryData.scicon}
                    qaicon={summaryData.qaicon}
                    exiticon={summaryData.exiticon}
                    scolicon={summaryData.scolIcon}
                    sapprovedicon={summaryData.sapprovedicon}
                    srejicon={summaryData.srejicon}
                    searchUser={summaryData.searchUser}

                    totalcontainericon={summaryData.totalcontainericon}
                    pendingicon={summaryData.pendingicon}
                    approveicon={summaryData.approveicon}
                    rejecticon={summaryData.rejecticon}
                    dispatchicon={summaryData.dispatchicon}
                    className={`text-white`}
                    // targetUrl={"/client-list"}
                    targetUrl={product.name === Config.dashboardTypes.Clients
                      ? "/client-list/1"
                      : product.name === Config.dashboardTypes.Pending
                        ? "/client-list/2"
                        : product.name === Config.dashboardTypes.Samples_Collected
                          ? "/client-list/3"
                          : product.name === Config.dashboardTypes.Samples_Approved
                            ? "/client-list/4"
                            : product.name === Config.dashboardTypes.Samples_Rejected
                              ? "/client-list/5"
                              : product.name === Config.dashboardTypes.SampleRTE
                                ? "/client-list/6"
                                : product.name === Config.dashboardTypes.Approved_Clients
                                  ? "/client-list/7"
                                  : product.name === Config.dashboardTypes.Pending_Exit
                                    ? "/client-list/8"
                                    : product.name === Config.dashboardTypes.Pending_Client
                                      ? "/client-list/9"
                                      : product.name === Config.dashboardTypes.Total_Container
                                        ? "/client-list/10"
                                        : product.name === Config.dashboardTypes.Pending_Container
                                          ? "/client-list/11"
                                          : product.name === Config.dashboardTypes.Approved_Container
                                            ? "/client-list/12"
                                            : product.name === Config.dashboardTypes.Rejected_Container
                                              ? "/client-list/13"
                                              : product.name === Config.dashboardTypes.Dispatched_Container
                                                ? "/client-list/14"
                                                : "/"
                    }
                    // `/client-list/${status}/${id}`
                    colorIndex={index}
                  />
                );
              }


            })}
        </BookingGrid>}
      {user.userType == Config.userType.DOCUMENTCOLLECTOR &&
        <BookingGrid>
          <Link to="/search">
            <Box className="bg-[#A9ECBF] flex justify-center flex-col h-48   items-center">
              <BoxTitle className=" items-center text-[#15a546]">Search Client</BoxTitle>
              <IconDoc className="text-[#43C16E]"><CiSearch /></IconDoc>
              {/* <BoxImage src={Images.AddClient} /> */}

            </Box>

          </Link>
          <Link to="/client-list">
            <Box className="bg-[#fdba74] flex justify-center flex-col h-48  items-center" >


              <BoxTitle className="text-[#ea580c] items-center ">View Client</BoxTitle>
              <IconDoc className="text-[#ea580c]" ><LuView /></IconDoc>


            </Box>
          </Link>
        </BookingGrid>
      }
    </BookingSummary>
  );
};

// ...

const Card = ({

  qaicon, insicon, scicon, dcicon,
  srejicon, sapprovedicon, scolicon, exiticon, clienticon, pending, pendingClient, collectedClient, approvedClient, pendingExit,
  name, totalcontainericon, pendingicon, approveicon, rejecticon, dispatchicon,
  desc,
  count,
  balance,
  className,
  searchUser,
  colorIndex,
  type,
  targetUrl,
}) => {

  const cardColors = [
    "#FFD8BE",
    "#C7C7FF",
    "#A9ECBF",
    "#F3BBE1",
    "#79DBFA",

    "#9eaecf",
    "#6ee7b7",
    "#fde68a",
    "#a5b4fc",
  ];
  const iconColor = [
    "#FCA147",
    "#9595FF",
    "#43C16E",
    "#DC5BB7",
    "#007499",

    "#4b5563",
    "#10b981",
    "#f59e0b",
    "#6366f1",
  ];

  const textColor = [
    "#fb850f",
    "#6060f5",
    "#15a546",
    "#e932b5",
    "#007499",

    "#4b5563",
    "#059669",
    "#d97706",
    "#4f46e5",
  ];

  const [isHovered, setIsHovered] = useState(false);
  const cardIndex = colorIndex % cardColors.length;
  const iconIndex = colorIndex % iconColor.length;
  const textIndex = colorIndex % textColor.length;
  const color = cardColors[cardIndex];
  const iconcolor = iconColor[iconIndex];
  const textcolor = textColor[textIndex];

  const leftPortionStyle = {
    backgroundColor: color,
  };
  const textStyle = {
    color: iconcolor,
    borderColor: iconcolor,

  };
  const textColorStyle = {
    color: textcolor,
    borderColor: textcolor,
  };
  // const handleArrowButtonClick = () => {
  //   if (name == Config.dashboardTypes.Clients && count !== 0) {
  //     window.open(targetUrl);
  //   }
  // };
  const isClickable = count > 0;

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  return (
    <>
      {/* <Link to={targetUrl}> */}
      <Link to={isClickable ? targetUrl : "#"}>
        <Box
          style={{
            ...leftPortionStyle,
          }}
          className={`${className} ${isClickable ? "cursor-pointer" : ""} `}
          // onClick={handleArrowButtonClick}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}

        >
          <div className="md:h-40 md:mt-10 ">

            {type == Config.dashboardTypes.Document_Collector && (
              <Icon style={textStyle} >{dcicon}</Icon>
            )}

            {(type == Config.dashboardTypes.Sample_Collector || type == Config.dashboardTypes.SampleTC) && (
              <Icon style={textStyle}>{scicon}</Icon>
            )}
            {type == Config.dashboardTypes.Quality_Assurer && (
              <Icon style={textStyle}>{qaicon}</Icon>
            )}
            {type == Config.dashboardTypes.Inspector && (
              <Icon style={textStyle}>{insicon}</Icon>
            )}
            {type == Config.dashboardTypes.Clients && (
              <Icon style={textStyle} >{clienticon}</Icon>
            )}

            {type == Config.dashboardTypes.Pending_Exit && (
              <Icon style={textStyle} >{pendingExit}</Icon>
            )}
            {type == Config.dashboardTypes.Pending && (
              <Icon style={textStyle} >{pending}</Icon>
            )}
            {type == Config.dashboardTypes.Pending_Client && (
              <Icon style={textStyle} >{pendingClient}</Icon>
            )}
            {type == Config.dashboardTypes.Collected_Clients && (
              <Icon style={textStyle} >{collectedClient}</Icon>
            )}
            {type == Config.dashboardTypes.Approved_Clients && (
              <Icon style={textStyle} >{approvedClient}</Icon>
            )}
            {(type == Config.dashboardTypes.Sample_Collected || type == Config.dashboardTypes.Samples_Collected) && (
              <Icon style={textStyle} >{scolicon}</Icon>
            )}

            {(type == Config.dashboardTypes.Sample_Rejected || type == Config.dashboardTypes.Samples_Rejected) && (
              <Icon style={textStyle}>{srejicon}</Icon>
            )}
            {(type == Config.dashboardTypes.Sample_Approved || type == Config.dashboardTypes.Samples_Approved) && (
              <Icon style={textStyle}>{sapprovedicon}</Icon>
            )}
            {type == Config.dashboardTypes.SampleRTE && (
              <Icon style={textStyle}>{exiticon}</Icon>
            )}

            {type == Config.dashboardTypes.Total_Container && (
              <Icon style={textStyle}>{totalcontainericon}</Icon>
            )}
            {type == Config.dashboardTypes.Pending_Container && (
              <Icon style={textStyle}>{pendingicon}</Icon>
            )}
            {type == Config.dashboardTypes.Approved_Container && (
              <Icon style={textStyle}>{approveicon}</Icon>
            )}
            {type == Config.dashboardTypes.Rejected_Container && (
              <Icon style={textStyle}>{rejecticon}</Icon>
            )}
            {type == Config.dashboardTypes.Dispatched_Container && (
              <Icon style={textStyle}>{dispatchicon}</Icon>
            )}


            <div className=" w-full ">
              <BoxCount className={className}>
                <Count>


                  <span>{count < 10 ? `0${count}` : count}</span>
                  {/* <span>{count < 10 && count != 0 ? `0${count}` : count}</span> */}
                </Count>
              </BoxCount>
              <BoxTitle style={textColorStyle}>
                {name}

              </BoxTitle>
              <BoxSubTitle style={textColorStyle}>
                {desc}

              </BoxSubTitle>
            </div>


          </div>
        </Box>
      </Link>
    </>
  );
};

const BookingSummary = tw.div`  `;

const BookingGrid = tw.div`grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5  mt-10  gap-4 text-center`;
// const BookingGridDoc = tw.div`grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3   mt-10  gap-4 text-center`;
const BookingGridDoc = tw.div`flex  md:flex-row flex-col space-y-5 md:space-y-0 md:space-x-12  mt-10 md:mt-12`;
const BoxTitle = tw.h3`font-medium text-lg  text-white  capitalize mb-1.5 `;
const BoxSubTitle = tw.h3`font-normal text-xs  text-white `;
const BoxTitleDoc = tw.h3`font-semibold text-base md:text-lg flex justify-start text-[#05a66a]  capitalize mb-3 `;
const Box = tw.div` w-full  relative md:h-64 grid  rounded-2xl box-border p-2 shadow-md `;
const BoxDoc = tw.div`  relative  bg-[#A9ECBF]  w-full md:w-[16rem] h-64 md:h-72 rounded-md pl-4 pr-1 pt-5 pb-2.5 md:pl-6 md:pr-2 shadow`;
const BoxCount = tw.p`font-medium text-4xl    flex  space-x-1  text-white justify-center my-3  `;
const BoxCounts = tw.span`font-light text-xs   flex    text-white justify-center my-1  `;
const Icon = tw.div`text-white text-4xl mt-4 flex items-center justify-center`;
const IconDoc = tw.div`text-white text-4xl flex justify-center `;
const CountsRight = tw.span`flex w-full justify-center `;
const Count = tw.span`flex  `;
// const BoxImage = tw.img`absolute bottom-0 right-0 h-56 `
const BoxImage = tw.img` h-48  md:h-56  absolute bottom-0 right-0`
export default Summary;
