import React, { useState } from "react";
import tw from "tailwind-styled-components";
import { Formik, Field, Form } from "formik";
import {
    InputGroup,
    FieldWrapper,
    Label,
    SubmitBtn,
    CombineInputGroup,
} from "../Styles/InputStyles";
import Loading from "../Loading";

import Select from "@mui/material/Select";
import Model from "../Model";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import MenuItem from "@mui/material/MenuItem";

const EditUserStatus = ({
    AddUserLoading,
    postAddUser,
    setOpenUploadModel,
    setEditStatus,
    selectedData,
    data,
}) => {

    const { t, i18n } = useTranslation();
    const InitialValues = {

        status: selectedData?.status,

        id: selectedData?.id,
    };
    // console.log(data)
    const SubmitHandler = (values) => {
        //console.log(values.roles);

        postAddUser(values);
    };




    return (
        <Model width={`w-11/12 max-w-lg `} setOpenModel={setEditStatus}>
            <Title>Update Status</Title>

            {AddUserLoading && <Loading />}

            {!AddUserLoading && (
                <Formik initialValues={InitialValues} onSubmit={SubmitHandler}>
                    <Form>
                        <Wrapper>
                            <InputGroup>
                                <Label htmlFor="status">Status</Label>
                                <FieldWrapper $select={true}>
                                    <Field required name="status" id="status" autoComplete="off">
                                        {(props) => (
                                            <Select
                                                className="w-full h-full pl-2"
                                                {...props.field}
                                            >
                                                <MenuItem value="1">Pending</MenuItem>
                                                <MenuItem value="2">Collected</MenuItem>
                                                <MenuItem value="3">Ready</MenuItem>
                                                <MenuItem value="4">Rejected</MenuItem>
                                                <MenuItem value="5">Approved</MenuItem>

                                            </Select>
                                        )}
                                    </Field>
                                </FieldWrapper>
                            </InputGroup>



                        </Wrapper>
                        <BtnWrapper>
                            <SubmitBtn type="submit">Update</SubmitBtn>
                        </BtnWrapper>
                    </Form>
                </Formik>
            )}
        </Model>
    );
};

const Wrapper = tw.div`
grid  gap-6 my-6 px-1`;
const SampleFile = tw.a` w-full pt-3 text-blue-500 underline text-sm text-center`;

const Title = tw.h1`text-lg md:text-xl font-medium text-center text-gray-800`;
const BtnWrapper = tw.div`flex justify-center items-center mt-8`;

export default EditUserStatus;
