import React, { useState, useEffect } from "react";
import tw from "tailwind-styled-components";
import { Link, useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import axios from "axios";
import Config from "../../Config";
import Loading from "../../Components/Loading";
import Navbar from "../../Components/Navbar";
import {
  BoxContainer,
  Underline,
  Page,
} from "../../Components/Styles/PageStyles";
import { InputGroup, Label } from "../../Components/Styles/InputStyles";
import NotFoundModel from "../../Components/NotFoundModel";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import TableContainers from "../../Components/DocumentCollectionUser/TableContainers";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Field, Form, Formik } from "formik";
const ContainerList = () => {
  const user = useSelector((state) => state.UserReducer.user);
  const { id, blNumber } = useParams();
  const [viewDetail, setViewDetail] = useState(false);
  const [selectedData, setSelectedData] = useState("");
  const [filter, setFilter] = useState("1");
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const fetchFunction = async () => {
    const body = new FormData();
    body.append("blNumber", blNumber);
    body.append("id", id);
    // body.append("processType", filter);
    return await axios.post(`${Config.apiUrl}/get-container-by-blno`, body, {
      headers: {
        authorization: "Bearer" + " " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const getClientListSuccess = (res) => {
    if (res.data.status == "NOK" || res.data.data == false) error = true;
  };

  const getClientListError = (res) => {};

  const {
    isLoading: isLoading,
    mutate: getClientListMutate,
    error,
    data,
    isRefetching,
  } = useMutation(fetchFunction, {
    onSuccess: getClientListSuccess,
    onError: getClientListError,
  });

  useEffect(() => {
    getClientListMutate();
  }, []);
  // useEffect(() => {
  //   if (filter) {
  //     getClientListMutate();
  //   }
  // }, [filter]);

  return (
    <>
      <Navbar />

      <Page>
        <BoxContainer>
          <div className="flex w-full justify-between items-center">
            <div>
              <Title>Container List</Title>
              <Underline />
            </div>
            {/* <Filters setFilter={setFilter} filter={filter} user={user} t={t} /> */}
          </div>

          {!error && !isLoading && data?.data?.data?.containers && (
            <TableWrapper>
              <TableContainers
                ApiData={error ? [] : data?.data?.data?.containers}
                setSelectedData={setSelectedData}
                selectedData={selectedData}
                setViewDetail={setViewDetail}
              />
            </TableWrapper>
          )}

          {(isLoading || isRefetching) && <Loading />}
          {(error || data?.data?.data?.containers == 0) && !isLoading && (
            <NotFoundModel />
          )}
        </BoxContainer>
      </Page>
    </>
  );
};

const Filters = ({ t, filter, setFilter }) => {
  return (
    <Formik>
      {(formikProps) => (
        <>
          <Form>
            <UploadWrapper>
              <InputGroup
                className="max-w-xs lg:ml-20"
                style={{ flexBasis: "content" }}
              >
                <Label htmlFor="filter">{t("Search")}</Label>

                <Select
                  autoComplete="off"
                  className="max-w-xs md:w-36 pl-2 bg-transparent text-sm p-0 border-none"
                  style={{ height: "40px" }}
                  value={filter}
                  onChange={(e) => {
                    setFilter(e.target.value);
                  }}
                >
                  <MenuItem value="1">Automate</MenuItem>

                  <MenuItem value="2">Manual</MenuItem>
                </Select>
              </InputGroup>
            </UploadWrapper>
          </Form>
        </>
      )}
    </Formik>
  );
};

const TableWrapper = tw.h1`my-10 border bg-white   rounded-md overflow-auto`;

const Title = tw.h1`text-xl  text-gray-800 whitespace-nowrap   font-medium`;

const UploadWrapper = tw.div`
 w-full flex justify-end space-x-4 items-center `;

const Button = tw.button`text-white bg-custom-green hover:bg-green-700 w-40 flex items-center space-x-1 justify-center h-10   text-xs whitespace-nowrap rounded`;

const ApplyBtn = tw.button`${(p) =>
  p.$active
    ? "bg-gray-100"
    : ""} border whitespace-nowrap w-28 md:w-40 !mr-auto border-gray-200 flex space-x-1.5 justify-center items-center text-white  rounded-md px-6  cursor-pointer bg-custom-green hover:bg-green-700  h-10 text-sm 
     `;
const FilterContainer = tw.div`
grid grid-cols-3 w-full gap-4 md:gap-4 lg:flex  items-end mt-12 md:mt-8`;
const ExportWrapper = tw.button`bg-custom-green hover:bg-green-700 h-10 w-10 rounded-md text-center justify-center px-2 items-center`;
export default ContainerList;
