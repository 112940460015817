import React, { useState } from "react";
import tw from "tailwind-styled-components";
import styled from "styled-components";
import { Formik, Field, Form } from "formik";
import {
  InputGroup,
  FieldWrapper,
  Label,
  SubmitBtn,
  CombineInputGroup,
} from "../Styles/InputStyles";
import Loading from "../Loading";
import MenuItem from "@mui/material/MenuItem";
import Select from "react-select";
import Model from "../Model";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
const AddFarmerModel = ({
  AddSubAgentLoading,
  postFarmerUser,

  setOpenUploadModel,

  data,
  // companyArr,
}) => {
  const user = useSelector((state) => state.UserReducer.user);
  const { t, i18n } = useTranslation();
  const InitialValues = {

  };
  // 
  const cropList = data?.map((item) => ({
    value: item.cropType,
    label: item.cropName,
  }))
  // 
  // console.log(data)
  const SubmitHandler = (values) => {

    postFarmerUser(values);
  };

  const genderOption = [
    { value: "1", label: "Male" },
    { value: "2", label: "Female" },
  ];

  return (
    <Model width={`w-11/12 max-w-3xl `} setOpenModel={setOpenUploadModel}>
      <Title>Add Marketing </Title>

      {AddSubAgentLoading && <Loading />}

      {!AddSubAgentLoading && (
        <Formik initialValues={InitialValues} onSubmit={SubmitHandler}>
          <Form>
            <Wrapper>


              <CombineInputGroup>

                <InputGroup>
                  <Label htmlFor="cropType">CropType</Label>
                  <FieldWrapper $select={true}>
                    <Field required name="cropType" id="cropType" autoComplete="off"  >
                      {(props) => (
                        <Select
                          className="w-full h-full"

                          required
                          options={cropList}
                          classNamePrefix="select"

                          onChange={(val) => {
                            props.form.setFieldValue("cropType", val.value)
                          }
                          }

                        />
                      )}
                    </Field>
                  </FieldWrapper>
                </InputGroup>

                <InputGroup>
                  <Label htmlFor="moisture">Moisture</Label>
                  <FieldWrapper className="relative">
                    <Field
                      type="number"
                      name="moisture"
                      id="moisture"
                      autoComplete="off"
                      className="truncate pr-2"
                      required

                    />
                    <PercentageSign>%</PercentageSign>
                  </FieldWrapper>

                </InputGroup>
                <InputGroup>
                  <Label htmlFor="recvdate">Receiving Date</Label>
                  <FieldWrapper>
                    <Field
                      type="date"
                      name="recvdate"
                      id="recvdate"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>
              </CombineInputGroup>

              <CombineInputGroup>
                <InputGroup>
                  <Label htmlFor="gradea">Quantity Grade A (kg)</Label>
                  <FieldWrapper>
                    <Field
                      type="number"
                      name="gradea"
                      id="gradea"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>
                <InputGroup>
                  <Label htmlFor="gradeb">Quantity Grade B (kg)</Label>
                  <FieldWrapper>
                    <Field
                      type="number"
                      name="gradeb"
                      id="gradeb"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>
                {user.companyName == "Kimolo Super Rice Ltd" && (
                  <>
                    <InputGroup>
                      <Label htmlFor="gradec">Quantity Grade C (kg)</Label>
                      <FieldWrapper>
                        <Field
                          type="number"
                          name="gradec"
                          id="gradec"
                          autoComplete="off"
                          className="truncate"
                          required
                        />
                      </FieldWrapper>
                    </InputGroup>
                    <InputGroup>
                      <Label htmlFor="graded">Quantity Grade D (kg)</Label>
                      <FieldWrapper>
                        <Field
                          type="number"
                          name="graded"
                          id="graded"
                          autoComplete="off"
                          className="truncate"
                          required
                        />
                      </FieldWrapper>
                    </InputGroup>
                  </>



                )}

              </CombineInputGroup>





              <CombineInputGroup>
                <InputGroup>
                  <Label htmlFor="pricea">Price Grade A(Tsh/kg)</Label>
                  <FieldWrapper>
                    <Field
                      type="number"
                      name="pricea"
                      id="pricea"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>

                <InputGroup>
                  <Label htmlFor="priceb">Price Grade B(Tsh/kg)</Label>
                  <FieldWrapper>
                    <Field
                      type="number"
                      name="priceb"
                      id="priceb"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>
                {user.companyName == "Kimolo Super Rice Ltd" && (
                  <>
                    <InputGroup>
                      <Label htmlFor="pricec">Price Grade C(Tsh/kg)</Label>
                      <FieldWrapper>
                        <Field
                          type="number"
                          name="pricec"
                          id="pricec"
                          autoComplete="off"
                          className="truncate"
                          required
                        />
                      </FieldWrapper>
                    </InputGroup>

                    <InputGroup>
                      <Label htmlFor="priced">Price Grade D(Tsh/kg)</Label>
                      <FieldWrapper>
                        <Field
                          type="number"
                          name="priced"
                          id="priced"
                          autoComplete="off"
                          className="truncate"
                          required
                        />
                      </FieldWrapper>
                    </InputGroup>



                  </>)}
              </CombineInputGroup>



            </Wrapper>
            <BtnWrapper>
              <SubmitBtn type="submit">Add</SubmitBtn>
            </BtnWrapper>
          </Form>
        </Formik>
      )}
    </Model>
  );
};

const Wrapper = tw.div`
grid  gap-6 my-6 px-1`;


const Title = tw.h1`text-lg md:text-xl font-medium text-center text-gray-800`;
const BtnWrapper = tw.div`flex justify-center items-center mt-8`;

const PercentageSign = styled.span`
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translateY(-50%);
`;
export default AddFarmerModel;
