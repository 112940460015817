import React, { useEffect, useState } from "react";
import {
  Page,
  BoxContainer,
  BoxSubTitle,
  ErrorText,
} from "../../Components/Styles/PageStyles";
import { PreviewBtn } from "../../Components/Styles/InputStyles";
import { useNavigate, useParams } from "react-router-dom";
import Images from "../../Images";
import tw from "tailwind-styled-components";
import Config from "../../Config";
import { useQuery, useMutation } from "react-query";
import axios from "axios";
import Table from "../../Components/Search/Table";
import ViewModel from "../../Components/Search/ViewModel";
import Loading from "../../Components/Loading";
import NotFoundModel from "../../Components/NotFoundModel";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { BsSearch } from "react-icons/bs";
import { useSelector } from "react-redux";
import Navbar from "../../Components/Navbar";
import SingleEntry from "../../Components/Search/SingleEntry";
import { useTranslation } from "react-i18next";
import { MdAdd } from "react-icons/md";
const Search = () => {
  const navigate = useNavigate();
  const { email } = useParams();
  const { t, i18n } = useTranslation();
  const user = useSelector((state) => state.UserReducer.user);

  const [customerData, setCustomerData] = useState({
    fullName: "",
    email: "",
    customerId: "",
  });
  const [openUploadModel, setOpenUploadModel] = useState(false);
  const [openViewModel, setOpenViewModel] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [addUser, setAddUser] = useState(false);
  const [searchType, setSearchType] = useState("company");
  const [searchValue, setSearchValue] = useState("");
  const [placeholderValue, setPlaceholderValue] = useState(
    "search by phone number e.g 1234567890 or Company Name"
  );
  const [searchInputNumber, setSearchInputNumber] = useState("");
  const [searchInputName, setSearchInputName] = useState("");
  // useEffect(() => {
  //   if (searchType == "number" || searchType == "company") {
  //     setPlaceholderValue(
  //       "search by phone number e.g 1234567890 or Company Name"
  //     );
  //   }
  // }, [searchType]);
  useEffect(() => {
    if (searchType === "number") {
      setPlaceholderValue("search by phone number e.g 1234567890");
    } else if (searchType === "company") {
      setPlaceholderValue("search by company name");
    }
  }, [searchType]);

  useEffect(() => {
    setSearchValue("");
  }, [searchType]);
  const fetchFunction = async (type, value) => {
    if (value)
      return await axios.get(
        `${Config.apiUrl}/get-client-details/${type}/${value}`,
        // Config.AxiosConfig
        {
          headers: {
            Authorization: "Bearer" + " " + user.token,
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }
      );
    else return null;
  };

  const { isLoading, error, data, refetch, isRefetching } = useQuery(
    "GetSmsReports",
    () => fetchFunction(searchType, searchValue)
  );

  useEffect(() => {
    if (!searchValue) refetch();
  }, [searchValue]);

  const ChangeHandler = (e) => setSearchValue(e.target.value);
  const isValidMobileNumber = (value) => /^[0-9]{9,15}$/.test(value);

  const SubmitHandler = (e) => {
    e.preventDefault();
    // if (!isValidMobileNumber(searchValue)) {
    //   toast.error("Mobile number must be between 9 and 15 digits.");
    //   return;
    // }
    refetch();
    // setSearchInputNumber(searchValue);
    // setSearchInputName(searchValue);
    if (searchType === "number") {
      setSearchInputNumber(searchValue);
      setSearchInputName("");
    } else if (searchType === "company") {
      setSearchInputName(searchValue);
      setSearchInputNumber("");
    }
  };
  console.log(error?.response?.data?.status);
  const AddUserFunction = async (values) => {
    const body = new FormData();
    body.append("name", values.name);
    body.append("mobile", values.mobile);
    body.append("companyName", values.companyName);
    body.append("address", values.address);
    body.append("email", values.email);
    return await axios.post(`${Config.apiUrl}/create-client`, body, {
      headers: {
        authorization: "Bearer" + " " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const onAddUserSuccess = (data) => {
    console.log(data);
    if (data?.data?.status == "OK") {
      toast.success(data?.data?.msg || "Success");
    } else {
      toast.error(data?.data?.msg);
    }

    setSelectedItem("");
    setAddUser(false);
    refetch();
  };

  const onAddUserError = (data) => {
    setAddUser(false);
    setSelectedItem("");
    toast.error(data?.response?.data?.msg || "An Error Occurred");
  };

  const { isLoading: AddSubAgentLoading, mutate: postUser } = useMutation(
    AddUserFunction,
    {
      onSuccess: onAddUserSuccess,
      onError: onAddUserError,
    }
  );

  // const SubmitHandler = (values) => {
  //   postUser({ ...values });
  // };

  return (
    <>
      <Navbar />
      <Page>
        <BoxContainer>
          <BoxTitle className="text-center">
            <p>Search</p>
          </BoxTitle>

          {openViewModel && (
            <ViewModel
              setOpenViewModel={setOpenViewModel}
              selectedItem={selectedItem}
              searchType={searchType}
            />
          )}
          {addUser && (
            <SingleEntry
              postUser={postUser}
              AddSubAgentLoading={AddSubAgentLoading}
              setAddUser={setAddUser}
              data={data?.data}
              selectedItem={selectedItem}
              searchInputNumber={searchInputNumber}
              searchInputName={searchInputName}
            />
          )}
          <Wrapper>
            <SearchWrapper>
              <SearchInput onSubmit={SubmitHandler}>
                <input
                  // type="text"
                  type={searchType === "number" ? "number" : "text"}
                  placeholder={placeholderValue}
                  autoFocus
                  onChange={ChangeHandler}
                  value={searchValue}
                />
                <SearchIcon type="submit">
                  <BsSearch className="w-4 h-4 text-white" />
                </SearchIcon>
              </SearchInput>
              <Select
                autoComplete="off"
                className="w-44 pl-2 bg-transparent text-sm p-0 border-none"
                style={{ height: "40px" }}
                value={searchType}
                onChange={(e) => setSearchType(e.target.value)}
              >
                <MenuItem value="company">Company Name</MenuItem>
                <MenuItem value="number">Mobile Number</MenuItem>
              </Select>
            </SearchWrapper>

            {(isLoading || isRefetching) && <Loading />}

            {error && !isLoading && !isRefetching && (
              <>
                <NotFoundText>
                  No client found associated with this phone number or company
                  name.
                </NotFoundText>
                {/* <div className="w-full  flex justify-center items-center "></div> */}
                {error?.response?.data?.status == "NOK" && (
                  <Uploader setAddUser={setAddUser} t={t} />
                )}
              </>
            )}

            {!isLoading && !isRefetching && !error && !data && (
              <PlaceholderModalComp type={searchType} />
            )}

            {!isLoading && !error && !isRefetching && data && (
              <TableWrapper>
                <Table
                  ApiData={data?.data?.data}
                  setSelectedItem={setSelectedItem}
                  setOpenViewModel={setOpenViewModel}
                  searchType={searchType}
                  setAddUser={setAddUser}
                  user={user}
                />
              </TableWrapper>
            )}
          </Wrapper>
        </BoxContainer>
      </Page>
    </>
  );
};

const PlaceholderModalComp = ({ type }) => (
  <div className="mt-10 md:mt-16">
    <Figure>
      <img src={Images.SearchImage} alt="image" />
    </Figure>
    <PlaceholderText>
      Please enter phone number or company name to get client details
    </PlaceholderText>
  </div>
);

const Uploader = ({ setAddUser, t }) => {
  return (
    <UploadWrapper>
      <Button type="button" onClick={() => setAddUser(true)}>
        <MdAdd className="text-white w-5 h-5" /> <p>{"Add Client"}</p>
      </Button>
    </UploadWrapper>
  );
};

const Wrapper = tw.div`
mt-10 mb-20`;
const UploadWrapper = tw.div`
 w-full flex justify-center space-x-4 items-center `;
const SearchWrapper = tw.div`flex items-center space-x-2 max-w-2xl mx-auto`;
const SearchInput = tw.form`w-11/12 mx-auto field-wrapper relative pl-2  rounded-md  bg-white  w-full   border-2 border-gray-200 flex items-center overflow-hidden`;

const SearchIcon = tw.button`absolute -top-1 -right-1 -bottom-1 px-4 grid place-items-center bg-custom-green cursor-pointer  transition duration-200`;

const TableWrapper = tw.h1`my-10 border   rounded-md overflow-auto`;

const PlaceholderText = tw.p`text-gray-400 text-sm md:text-base text-center`;
const RecordNotFoundText = tw.p` text-red-400 text-base md:text-lg text-center`;
const Figure = tw.div`w-44 mx-auto mb-8`;
const BoxTitle = tw.div`text-xl  text-gray-700 font-medium  space-x-2    cursor-pointer `;
const NotFoundText = tw.h3`
text-md md:text-lg text-gray-400 font-light text-center mt-20 my-10`;

const Button = tw.button`text-white bg-custom-green hover:bg-green-700 w-40 flex items-center space-x-1 justify-center h-10   text-xs whitespace-nowrap rounded`;
export default Search;
