import React, { useState, useEffect } from "react";
import tw from "tailwind-styled-components";
import { Formik, Field, Form, ErrorMessage } from "formik";
import {
    FieldWrapper,
    Label,
    SubmitBtn,
    CombineInputGroup,
    InputGroup,
} from "../Styles/InputStyles";
import Loading from "../Loading";
import Model from "../Model";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import moment from "moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";

const SingleEntry = ({
    SingleEntryLoading,
    postSingleEntry,
    setOpenUploadModel,

}) => {
    // console.log("Hello: " + JSON.stringify(partner[0]?.name));

    const InitialValues = {
        reportName: "",
        startDate: "",
        endDate: "",

        operation: "1",
    };

    const validationSchema = Yup.object().shape({
        startDate: Yup.date().required("Start date is required"),
        endDate: Yup.date()
            .required("End date is required")
            .min(Yup.ref("startDate"), "End date must be after start date"),
    });

    const SubmitHandler = (values) => {
        // console.log(values.reportName.length);
        if (!values.operation) {
            toast.error("Please select report type");
            return;
        }
        if (values.reportName.length > 60) {
            toast.error("Report name must be at most 60 characters");
            return;
        }

        const body = new FormData();
        body.append("startDate", moment(values.startDate).format("YYYY-MM-DD"));
        body.append("endDate", moment(values.endDate).format("YYYY-MM-DD"));
        body.append("reportName", values?.reportName);
        body.append("operation", values?.operation);


        postSingleEntry(body);
    };

    return (
        <Model width={`w-11/12 max-w-lg`} setOpenModel={setOpenUploadModel}>
            <Title>Generate Report</Title>

            {SingleEntryLoading && <Loading />}

            {!SingleEntryLoading && (
                <>
                    <Formik
                        initialValues={InitialValues}
                        onSubmit={SubmitHandler}
                        validationSchema={validationSchema}
                    >
                        {(formikProps) => (
                            <Form>
                                <Wrapper>
                                    <InputGroup>
                                        <Label htmlFor="reportName">Report Name</Label>
                                        <FieldWrapper>
                                            <Field
                                                type="text"
                                                name="reportName"
                                                id="reportName"
                                                autoComplete="off"
                                                className="truncate"
                                                required
                                                placeholder="Report Name"
                                            />
                                        </FieldWrapper>
                                        {/* <ErrorMessage name="reportName" component={ErrorText} /> */}
                                    </InputGroup>
                                    <InputGroup>
                                        <Label htmlFor="operation">Report Type</Label>
                                        <FieldWrapper $select={true} >
                                            <Field
                                                type="text"
                                                name="operation"
                                                id="operation"
                                                autoComplete="off"
                                                className="truncate"
                                                required
                                            >
                                                {(props) => (
                                                    <Select
                                                        // style={{ height: 44 }}
                                                        // sx={{
                                                        //     "& .MuiOutlinedInput-notchedOutline": {
                                                        //         border: "0 none",
                                                        //     },
                                                        // }}
                                                        autoComplete="off"
                                                        className="w-full h-full pl-2"
                                                        {...props.field}
                                                    >
                                                        <MenuItem value="1">Production</MenuItem>
                                                        <MenuItem value="2">Harvesting</MenuItem>
                                                        <MenuItem value="3">Marketing</MenuItem>
                                                        <MenuItem value="4">Stock Management</MenuItem>
                                                        <MenuItem value="5">Transportation</MenuItem>
                                                    </Select>
                                                )}
                                            </Field>
                                        </FieldWrapper>
                                    </InputGroup>


                                    <CombineInputGroup>
                                        <InputGroup>
                                            <Label htmlFor="startDate">Start Date</Label>
                                            <FieldWrapper>
                                                <Field
                                                    name="startDate"
                                                    type="date"
                                                    id="startDate"
                                                    autoComplete="off"
                                                    required
                                                    max={new Date().toISOString().split("T")[0]}
                                                />
                                            </FieldWrapper>
                                        </InputGroup>

                                        <InputGroup>
                                            <Label htmlFor="endDate">End Date</Label>
                                            <FieldWrapper>
                                                <Field
                                                    name="endDate"
                                                    type="date"
                                                    id="endDate"
                                                    autoComplete="off"
                                                    required
                                                    max={new Date().toISOString().split("T")[0]}
                                                />
                                            </FieldWrapper>
                                        </InputGroup>
                                    </CombineInputGroup>
                                </Wrapper>
                                <BtnWrapper>
                                    <SubmitBtn type="submit">Generate Report</SubmitBtn>
                                </BtnWrapper>
                            </Form>
                        )}
                    </Formik>
                </>
            )}
        </Model>
    );
};




const ErrorText = tw.p`text-red-500 text-sm mt-1`;

const Wrapper = tw.div`
grid gap-6 my-6 px-1 `;

const Title = tw.h2` text-lg md:text-xl lg:text-2xl text-gray-600 font-medium mb-6 text-center`;
const BtnWrapper = tw.div` w-full flex items-center justify-center space-x-10 mt-8`;
export default SingleEntry;
