import React, { useEffect, useState } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';


ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);
export function MarketingArea({ data }) {
    const [filteredData, setFilteredData] = useState({});

    useEffect(() => {
        if (data) {
            setFilteredData(data?.data?.data);
        }
    }, [data?.data?.data]);
    // console.log(data?.data?.data)
    const labels = filteredData?.cropsMarketingCrops
        ? filteredData.cropsMarketingCrops.map((item) => item.name)
        : [];
    // console.log(labels)

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },

        },
    };



    const areaData = {
        labels,
        datasets: [
            {
                fill: true,
                label: 'Quantity of crop received in marketing',
                data: filteredData?.cropsMarketingCrops
                    ? filteredData.cropsMarketingCrops.map((item) => item.totalQuantity)
                    : [],
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
        ],
    };


    return <Line options={options} data={areaData} />;
}
